import { FC } from 'react';

import HSpacing from 'bloko/blocks/hSpacing';
import { EyeScaleSmallCrossedFalse } from 'bloko/blocks/icon';
import numberFormatter from 'bloko/common/numberFormatter';

import styles from './article-views-count.less';

interface ArticleViewsCountProps {
    count: number;
}

const ArticleViewsCount: FC<ArticleViewsCountProps> = ({ count }) => (
    <div className={styles.container}>
        {numberFormatter.format(String(count))}
        <HSpacing base={1} />
        <EyeScaleSmallCrossedFalse />
    </div>
);

export default ArticleViewsCount;
