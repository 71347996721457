import scrollToElement from 'bloko/common/scrollToElement';
import SessionStorageWrapper from 'bloko/common/storage/SessionStorageWrapper';

const SCROLL_AFTER_LOGIN = 'ARTICLE_RATING_SCROLL_AFTER_LOGIN';
const LOGIN_PAGE_NAME = 'account_login';

export const setScrollAfterLoginToStorage = (): void => SessionStorageWrapper.setItem(SCROLL_AFTER_LOGIN, 'true');

export const scrollAfterLoginIfNeeded = (element: HTMLDivElement, anonymous: boolean, hhtmFrom: string): void => {
    const hasStorageData = SessionStorageWrapper.getItem(SCROLL_AFTER_LOGIN) === 'true';
    const hasLoginHhtmFrom = hhtmFrom === LOGIN_PAGE_NAME;
    if (!anonymous && hasStorageData && hasLoginHhtmFrom) {
        scrollToElement(element, { centered: true });
    }
    hasStorageData && SessionStorageWrapper.removeItem(SCROLL_AFTER_LOGIN);
};
