import { FC, PropsWithChildren } from 'react';

import FullPageLayout from 'src/app/layouts/FullPageLayout';
import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';

const ReactMigrationFromXSLWrapper: FC<PropsWithChildren> = ({ children }) => (
    <FullPageLayout>
        <ContainerForXSL place="legacy-page-layout-xsl" />
        {children}
    </FullPageLayout>
);

export default ReactMigrationFromXSLWrapper;
