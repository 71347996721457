import { memo } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ARTICLE_BREADCRUMBS, ArticleBreadcrumb } from 'src/models/articlePage';

const BREADCRUMB_LINKS = {
    [ARTICLE_BREADCRUMBS.ArticlesMark]: '/articles?from=articles_article_head&hhtmFromLabel=articles_article_head',
    [ARTICLE_BREADCRUMBS.PartnerMark]: '/article/partner?from=partner_article_head&hhtmFromLabel=partner_article_head',
    [ARTICLE_BREADCRUMBS.BusinessMark]:
        '/articles/employers/own-business?from=own_business_article_head&hhtmFromLabel=own_business_article_head',
    [ARTICLE_BREADCRUMBS.YoungProfessionalsMark]:
        '/articles/students?from=young_professionals_mark_article_head&hhtmFromLabel=young_professionals_mark_article_head',
    [ARTICLE_BREADCRUMBS.PressReleaseMark]:
        '/articles/labour-market/companies-news?from=press_release_mark_article_head&hhtmFromLabel=press_release_mark_article_head',
};

const TrlKeys = {
    [ARTICLE_BREADCRUMBS.ArticlesMark]: 'articles',
    [ARTICLE_BREADCRUMBS.PartnerMark]: 'article.partnerMark',
    [ARTICLE_BREADCRUMBS.BusinessMark]: 'article.businessMark',
    [ARTICLE_BREADCRUMBS.YoungProfessionalsMark]: 'article.youngProfessionalsMark',
    [ARTICLE_BREADCRUMBS.PressReleaseMark]: 'article.pressReleaseMark',
};

interface BreadcrumbsItemProps {
    mark: ArticleBreadcrumb;
    isAlternativeDesign?: boolean;
}

const BreadcrumbsItem: TranslatedComponent<BreadcrumbsItemProps> = ({ trls, mark, isAlternativeDesign = false }) => {
    return (
        <span className={classnames('breadcrumbs', { 'breadcrumbs-planet': !isAlternativeDesign })}>
            <Link to={BREADCRUMB_LINKS[mark]} className="breadcrumbs__item">
                {isAlternativeDesign ? (
                    <Text importance={TextImportance.Tertiary}>{trls[TrlKeys[mark]]}</Text>
                ) : (
                    trls[TrlKeys[mark]]
                )}
            </Link>
        </span>
    );
};

export default translation(memo(BreadcrumbsItem));
