import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DefaultLayout from 'src/app/layouts/BlokoLayouts/DefaultLayout';
import { PageHead, PageTitle } from 'src/components/PageHead';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    'error.400': 'error.400.message',
    'error.403': 'error.403.message',
    'error.404': 'error.404.message',
    'error.500': 'error.500.tryagain',
    'error.599': 'error.599.tryagain',
    'error.default': 'error.500.tryagain',
};

const PageErrorWrapper: TranslatedComponent = ({ children, trls }) => {
    const errorCode = useSelector(({ errorCode }) => errorCode);
    if (errorCode) {
        const trlErrorCode = `error.${errorCode}`;
        const trlCode = trlErrorCode in TrlKeys ? trlErrorCode : 'error.default';
        const trl = trls[TrlKeys[trlCode as keyof typeof TrlKeys]];
        return (
            <>
                <PageHead>
                    <PageTitle>{trl}</PageTitle>
                </PageHead>
                <DefaultLayout>
                    <Column xs="4" s="8" m="12" l="16">
                        {trl}
                    </Column>
                </DefaultLayout>
            </>
        );
    }

    return <>{children}</>;
};

export default translation(PageErrorWrapper);
