import { FC } from 'react';

import styles from './margin-spacing.less';

interface MarginSpacingProps {
    small?: boolean;
}

// Because of margin-collapsing, it's important to use margins and not VSpacing before and after article body
const MarginSpacing: FC<MarginSpacingProps> = ({ small = false }) => (
    <div className={small ? styles.topMarginSmall : styles.topMargin} />
);

export default MarginSpacing;
