import { FC } from 'react';

import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { ArticleTypes } from 'src/models/articlePage/types';

import BlogArticle from 'src/pages/Article/components/Content/BlogArticle';
import LandingArticle from 'src/pages/Article/components/Content/LandingArticle';

const Content: FC = () => {
    const { type } = useSelectorNonNullable((state) => state.articlePage);

    return type === ArticleTypes.Promo ? <LandingArticle /> : <BlogArticle />;
};

export default Content;
