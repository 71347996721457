import { FC } from 'react';

import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import { useSelector } from 'src/hooks/useSelector';
import ContentHeader from 'src/pages/Article/components/ContentHeader';
import ArticleFooter from 'src/pages/Article/components/Footer';
import LandingHeader from 'src/pages/Article/components/LandingHeader';
import ArticleSidebarPortal from 'src/pages/Article/components/Sidebar';
import useScrollAnalytics from 'src/pages/Article/hooks/useScrollAnalytics';

const BlogArticle: FC = () => {
    const { id } = useSelector(({ articlePage }) => articlePage);

    useScrollAnalytics(id);

    return (
        <>
            <ContainerForXSL place="legacy-page-layout-xsl" />
            <Portal place="landingHeader">
                <LandingHeader />
            </Portal>
            <Portal place="articleContentHeader">
                <ContentHeader />
            </Portal>
            <Portal place="articleFooter">
                <ArticleFooter />
            </Portal>
            <Portal place="articleSidebar">
                <ArticleSidebarPortal />
            </Portal>
        </>
    );
};

export default BlogArticle;
