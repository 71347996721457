import React from 'react';
import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';
import Conversion from 'bloko/blocks/conversion';
import { H3 } from 'bloko/blocks/header';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

import InsiderSimpleParticipant from 'src/pages/Article/components/Sidebar/InsiderParticipants/InsiderSimpleParticipant';

const TrlKeys = {
    title: 'article.insiderInterview.participants',
    showLess: 'article.insiderInterview.participants.showLess',
    showMore: {
        one: 'article.insiderInterview.participants.showMore.one',
        some: 'article.insiderInterview.participants.showMore.some',
        many: 'article.insiderInterview.participants.showMore.many',
    },
};

const InsiderParticipantsBlock: TranslatedComponent = ({ trls }) => {
    const participants = useSelector((state) => state.insiderArticleSpecificData?.participants);
    const [expanded, toggleExpanded] = useToggleState(false);

    if (!participants) {
        return null;
    }

    return (
        <div className="cms-banners__banner">
            <div
                className={classnames('interview-participants', {
                    'interview-participants_collapsed': !expanded,
                    'interview-participants_expanded': expanded,
                })}
            >
                <H3>{trls[TrlKeys.title]}</H3>
                <VSpacing default={16} />
                <div className="interview-participants__show-less" onClick={toggleExpanded}>
                    <BlokoLink appearance={LinkAppearance.Pseudo}>{trls[TrlKeys.showLess]}</BlokoLink>
                </div>
                {participants.map((participant) => (
                    <InsiderSimpleParticipant participant={participant} key={participant.id} />
                ))}
                <div className="interview-participants__show-more" onClick={toggleExpanded}>
                    <BlokoLink appearance={LinkAppearance.Pseudo}>
                        <Conversion
                            hasValue={false}
                            value={participants?.length}
                            one={trls[TrlKeys.showMore.one]}
                            some={trls[TrlKeys.showMore.some]}
                            many={trls[TrlKeys.showMore.many]}
                        />
                    </BlokoLink>
                </div>
            </div>
        </div>
    );
};

export default translation(InsiderParticipantsBlock);
