import React, { ComponentType } from 'react';

import Column from 'bloko/blocks/column';

import DefaultLayout from 'src/app/layouts/BlokoLayouts/DefaultLayout';

interface SideBarLayoutProps {
    sidebar: ComponentType;
}

const SideBarLayout: React.FC<SideBarLayoutProps & React.PropsWithChildren> = ({
    children,
    sidebar: SideBarComponent,
}) => (
    <DefaultLayout>
        <Column xs="4" m="8" l="11" container>
            {children}
        </Column>
        <Column xs="4" m="4" l="5" container>
            <SideBarComponent />
        </Column>
    </DefaultLayout>
);

export default SideBarLayout;
