import React from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H3 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    otherInterviews: 'article.insiderInterview.otherInterviews',
};

const InsiderOtherInterviews: TranslatedComponent = ({ trls }) => {
    const otherInterviews = useSelector((state) => state.insiderArticleSpecificData?.otherInterviews);
    const employerId = useSelector((state) => state.articleEmployerInfo?.id);

    if (!otherInterviews || otherInterviews.length === 0) {
        return null;
    }

    return (
        <div className="cms-banners__banner">
            <H3>{trls[TrlKeys.otherInterviews]}</H3>
            <VSpacing default={16} />
            {otherInterviews.map(({ code, title }) => (
                <>
                    <BlokoLink
                        Element={Link}
                        to={`/interview/${code}${employerId ? `?employerId=${employerId}` : ''}`}
                        kind={LinkKind.Tertiary}
                    >
                        {title}
                    </BlokoLink>
                    <VSpacing default={8} />
                </>
            ))}
        </div>
    );
};

export default translation(InsiderOtherInterviews);
