import React, { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { InsiderDesignType } from 'src/models/articlePage';
import { ArticleTypes } from 'src/models/articlePage/types';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import InsiderLandingFooter from 'src/pages/Article/components/Footer/InsiderLandingFooter';
import MarginSpacing from 'src/pages/Article/components/Footer/MarginSpacing';
import PressReleaseFooter from 'src/pages/Article/components/Footer/PressReleaseFooter';
import Rating from 'src/pages/Article/components/Footer/Rating';
import ReadMore from 'src/pages/Article/components/Footer/ReadMore';

const ArticleFooter: FC = () => {
    const isInsider = useIsInsider();
    const articleRating = useSelector((state) => state.articleRating);
    const { id, type, insiderDesignType } = useSelector(({ articlePage }) => articlePage);

    if (isInsider && insiderDesignType === InsiderDesignType.Landing) {
        return (
            <>
                <MarginSpacing small />
                <InsiderLandingFooter />
            </>
        );
    }

    if (type === ArticleTypes.PressRelease) {
        return (
            <>
                <MarginSpacing small />
                <PressReleaseFooter />
            </>
        );
    }

    return (
        <>
            {articleRating && (
                <>
                    <MarginSpacing />
                    <Rating articleId={id} voteState={articleRating.voteState} likesCount={articleRating.likesCount} />
                </>
            )}
            <MarginSpacing />
            <ReadMore />
        </>
    );
};

export default ArticleFooter;
