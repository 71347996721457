import React, { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import { H1 } from 'bloko/blocks/header';
import Text, { TextSize, TextImportance } from 'bloko/blocks/text';

import HumanDate from 'src/components/HumanDate';
import { useSelector } from 'src/hooks/useSelector';
import { ARTICLE_BREADCRUMBS, ImageType } from 'src/models/articlePage';
import BreadcrumbsItem from 'src/pages/Article/components/Breadcrumbs/BreadcrumbsItem';
import ArticleLead from 'src/pages/Article/components/ContentHeader/ArticleLead';

import styles from './insider-simple-header.less';

const InsiderSimpleHeader: FC = () => {
    const { images, publicationTime } = useSelector(({ articlePage }) => articlePage);
    const titleImage = images?.find(({ type }) => type === ImageType.Title);
    const { title } = useSelector(({ pageMetaData }) => pageMetaData);

    return (
        <>
            <BreadcrumbsItem mark={ARTICLE_BREADCRUMBS.ArticlesMark} isAlternativeDesign />
            <H1>{title}</H1>
            <VSpacing default={4} />
            <ArticleLead small />
            <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                <HumanDate date={publicationTime} />
            </Text>
            <VSpacing default={28} />
            {titleImage && (
                <>
                    <figure className={styles.container}>
                        <img loading="lazy" src={titleImage.url} className={styles.image} alt={title} title={title} />
                        {titleImage.caption && (
                            <figcaption className={styles.description}>{titleImage.caption}</figcaption>
                        )}
                    </figure>
                    <VSpacing default={20} />
                </>
            )}
        </>
    );
};

export default InsiderSimpleHeader;
