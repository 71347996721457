import { VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    companyVacancies: 'article.insiderInterview.vacancies',
    industries: 'article.insiderInterview.industries',
};

const ArticleEmployerInfo: TranslatedComponent = ({ trls }) => {
    const info = useSelector((state) => state.articleEmployerInfo);

    if (!info) {
        return null;
    }

    return (
        <div className="cms-banners__banner">
            {info.logo && <img loading="lazy" src={info.logo} className="interview-company-logo" />}
            <div className="interview-company-block">
                <p>{info.name}</p>
                {info.siteUrl && (
                    <p>
                        <Link to={info.siteUrl}>{info.siteUrl}</Link>
                    </p>
                )}
            </div>
            {!!info.industries.length && (
                <div className="interview-company-block">
                    <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                        {trls[TrlKeys.industries]}
                    </Text>
                    {info.industries.map((industry) => (
                        <div key={industry}>
                            <p>{industry}</p>
                            <VSpacing default={4} />
                        </div>
                    ))}
                </div>
            )}
            <p>
                <Link to={`/employer/${info.id}`}>{trls[TrlKeys.companyVacancies]}</Link>
            </p>
        </div>
    );
};

export default translation(ArticleEmployerInfo);
