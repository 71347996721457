import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import VSeparator from 'src/components/VSeparator';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { InsiderDesignType } from 'src/models/articlePage';
import { ArticleTypes } from 'src/models/articlePage/types';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import EmployerInfoSidebar from 'src/pages/Article/components/Sidebar/InsiderEmployerInfo';
import InsiderOtherInterviews from 'src/pages/Article/components/Sidebar/InsiderOtherInterviews';
import InsiderParticipantsBlock from 'src/pages/Article/components/Sidebar/InsiderParticipants';
import SuitableVacancies from 'src/pages/Article/components/Sidebar/SuitableVacancies';
import VacanciesOfTheDay from 'src/pages/Article/components/Sidebar/VacanciesOfTheDay';

import styles from './sidebar.less';

const Sidebar: FC = () => {
    const suitableVacanciesCollection = useSelector((state) => state.suitableVacancies?.vacancies);
    const vacanciesOfTheDayCollection = useSelector((state) => state.vacanciesOfTheDay?.vacancies);
    const { type, insiderDesignType } = useSelector(({ articlePage }) => articlePage);

    const isZP = useIsZarplataPlatform();
    const isInsider = useIsInsider();

    // (suitableVacanciesCollection?.length > 0) expression does not allow eslint
    const showSuitableVacancies = suitableVacanciesCollection && suitableVacanciesCollection.length > 0;
    const showVacanciesOfTheDay = vacanciesOfTheDayCollection?.length > 0;
    const showArticleSidebar = showSuitableVacancies || showVacanciesOfTheDay;

    if (isInsider && insiderDesignType === InsiderDesignType.Simple) {
        return (
            <div className={styles.sidebar}>
                <EmployerInfoSidebar />
                <InsiderParticipantsBlock />
                <InsiderOtherInterviews />
            </div>
        );
    }

    if (
        (isInsider && insiderDesignType === InsiderDesignType.Landing) ||
        type === ArticleTypes.PressRelease ||
        isZP ||
        !showArticleSidebar
    ) {
        return null;
    }

    return (
        <div className={styles.sidebar}>
            {showSuitableVacancies && <SuitableVacancies collection={suitableVacanciesCollection} />}
            {showSuitableVacancies && showVacanciesOfTheDay && (
                <>
                    <VSpacing default={24} />
                    <VSeparator />
                    <VSpacing default={16} />
                </>
            )}
            {showVacanciesOfTheDay && <VacanciesOfTheDay collection={vacanciesOfTheDayCollection} />}
        </div>
    );
};

export default Sidebar;
