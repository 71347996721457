type VacancyShort = {
    click?: string;
    links: {
        desktop: string;
    };
};

export interface VacancyLinkQueryParams extends Record<string, string> {
    hhtmFromLabel: string;
}

type GetVacancyLinkFunction = (vacancy: VacancyShort) => string;

export const getVacancyLink: GetVacancyLinkFunction = ({ click, links }) => {
    return click || links.desktop;
};
