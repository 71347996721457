import { memo } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { ImageType, InsiderDesignType } from 'src/models/articlePage';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import HeaderDefault from 'src/pages/Article/components/LandingHeader/HeaderDefault';
import HeaderInsider from 'src/pages/Article/components/LandingHeader/HeaderInsider';

const LandingHeader = () => {
    const title = useSelector((state) => state.articlePage?.title);
    const publicationTime = useSelector((state) => state.articlePage?.publicationTime);
    const images = useSelector((state) => state.articlePage?.images);
    const insiderDesignType = useSelector((state) => state.articlePage?.insiderDesignType);
    const isInsider = useIsInsider();

    const image =
        images &&
        images.find((img) => {
            return insiderDesignType === InsiderDesignType.Landing
                ? img.type === ImageType.Landing
                : img.type === ImageType.Title;
        });

    if (isInsider && insiderDesignType === InsiderDesignType.Landing) {
        return <HeaderInsider title={title} publicationTime={publicationTime} image={image} />;
    } else if (!isInsider) {
        return <HeaderDefault title={title} publicationTime={publicationTime} image={image} />;
    }
    return null;
};

export default memo(LandingHeader);
