import { useEffect, useRef, useState, FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { ArticleRating } from 'src/models/articleRating';
import { UserType } from 'src/models/userType';

import VoteButton, { VOTE_BUTTONS } from 'src/pages/Article/components/Footer/Rating/VoteButton';
import AnonymousVoteButton from 'src/pages/Article/components/Footer/Rating/VoteButton/Anonymous';
import { scrollAfterLoginIfNeeded } from 'src/pages/Article/components/Footer/Rating/utils';

import styles from './article-rating.less';

interface RatingProps extends ArticleRating {
    articleId: string;
}

const Rating: FC<RatingProps> = ({ articleId, voteState, likesCount }) => {
    const [ratingState, setRatingState] = useState<ArticleRating>({ voteState, likesCount });
    const voteButtonProps = { articleId, ratingState, setRatingState };

    const anonymous = useSelector((state) => state.userType) === UserType.Anonymous;
    const backUrlForLogin = useSelector((state) => state.request.url);
    const hhtmFrom = useSelector((state) => state.analyticsParams.hhtmFrom);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!scrollRef.current) {
            return;
        }
        scrollAfterLoginIfNeeded(scrollRef.current, anonymous, hhtmFrom);
    }, [anonymous, hhtmFrom]);

    return (
        <div ref={scrollRef} className={styles.container}>
            {VOTE_BUTTONS.map((buttonType) =>
                anonymous ? (
                    <AnonymousVoteButton
                        key={buttonType}
                        buttonType={buttonType}
                        backUrlForLogin={backUrlForLogin}
                        {...voteButtonProps}
                    />
                ) : (
                    <VoteButton key={buttonType} buttonType={buttonType} {...voteButtonProps} />
                )
            )}
        </div>
    );
};

export default Rating;
