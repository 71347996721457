import { FC } from 'react';

import translation from 'src/components/translation';

import CompanyLink from 'src/pages/Article/components/Sidebar/VacancySummary/CompanyLink';
import VacancyLink, { VacancySummaryLinkProps } from 'src/pages/Article/components/Sidebar/VacancySummary/Link';

import styles from './vacancy-summary.less';

const VacancySummary: FC<VacancySummaryLinkProps> = ({ vacancy, ...props }) => {
    return (
        <div className={styles.container}>
            <VacancyLink vacancy={vacancy} {...props} />
            <CompanyLink vacancy={vacancy} />
        </div>
    );
};

export default translation(VacancySummary);
