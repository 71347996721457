import { FC, useMemo } from 'react';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import ReactMigrationFromXSLWrapper from 'src/components/ReactMigrationFromXSLWrapper';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
// eslint-disable-next-line @hh.ru/import-rules/no-internal-modules
import AnonymousEmployer from 'src/pages/AnonymousEmployer';

import Content from 'src/pages/Article/components/Content';

const Article: FC = () => {
    const pageMetaData = useSelector((state) => state.pageMetaData);
    const articlePage = useSelector((state) => state.articlePage);

    // tempexp_33175_start
    const isHH = useIsHhru();
    const query = useSelector((state) => state.router.location.query);
    const pathname = useSelector((state) => state.router.location.pathname);
    const hasUtmKeys = useMemo(() => Object.keys(query).some((key) => key.includes('utm')), [query]);
    const isEmployerB2BExp = useExperiment(
        'exp_33175_b2b_employer_register',
        isHH && hasUtmKeys && pathname === '/b2b',
        false
    );
    if (isEmployerB2BExp) {
        return <AnonymousEmployer />;
    }
    // tempexp_33175_end
    if (!articlePage) {
        return <ReactMigrationFromXSLWrapper />;
    }

    return (
        <PageLayout layout={EmptyLayout} {...pageMetaData}>
            <Content />
        </PageLayout>
    );
};

export default Article;
