import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import articleScroll from '@hh.ru/analytics-js-events/build/xhh/common/article/article_scroll';

const ScrollAnalyticsCheckpoint = {
    Middle: 'MIDDLE',
    End: 'END',
} as const;
type ScrollAnalyticsCheckpointType = (typeof ScrollAnalyticsCheckpoint)[keyof typeof ScrollAnalyticsCheckpoint];

type SendScrollAnalyticsFunction = (to: ScrollAnalyticsCheckpointType, articleId: string) => void;

const sendScrollAnalytics: SendScrollAnalyticsFunction = (checkpoint, articleId) => {
    Analytics.sendEvent('article', `scroll-to-${checkpoint.toLowerCase()}`, articleId);
    articleScroll({ to: checkpoint });
};

type UseScrollAnalyticsHook = (articleId: string) => void;

const useScrollAnalytics: UseScrollAnalyticsHook = (articleId) => {
    useEffect(() => {
        const endCheckpoint = document.querySelector('.HH-ScrollAnalytics-EndCheckpoint') as HTMLElement;
        if (!endCheckpoint) {
            return undefined;
        }

        const callback: IntersectionObserverCallback = ([entry], observer) => {
            if (entry.isIntersecting) {
                observer.disconnect();
                sendScrollAnalytics(ScrollAnalyticsCheckpoint.End, articleId);
            }
        };

        const options: IntersectionObserverInit = { rootMargin: '0px 0px 150px 0px' };

        const observer = new IntersectionObserver(callback, options);

        observer.observe(endCheckpoint);

        return () => observer.disconnect();
    }, [articleId]);
};

export default useScrollAnalytics;
