import Analytics from '@hh.ru/analytics-js';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useVacanciesOfTheDayViewAnalytics from 'src/components/VacanciesOfTheDay/hooks/useVacanciesOfTheDayViewAnalytics';
import translation from 'src/components/translation';
import { VacancyOfTheDay as VacancyOfTheDayType } from 'src/models/vacanciesOfTheDay';
import VacancySummary from 'src/pages/Article/components/Sidebar/VacancySummary';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

const TrlKeys = {
    header: 'index.headers.vod',
};

interface VacanciesOfTheDayProps {
    collection: VacancyOfTheDayType[];
}

const VacanciesOfTheDay: TranslatedComponent<VacanciesOfTheDayProps> = ({ trls, collection }) => {
    const vacanciesRefs = useVacanciesOfTheDayViewAnalytics(collection, VacancyOfTheDayLocation.Article);
    const vacancyLinkQueryParams = {
        hhtmFromLabel: 'articles_vacancy_day',
        location: VacancyOfTheDayLocation.Article,
    };

    return (
        <div>
            <H3>{trls[TrlKeys.header]}</H3>
            <VSpacing base={4} />
            <div className="vacancies-of-the-day">
                {collection.map((vacancy) => (
                    <div key={vacancy.vacancyId} className="vacancies-of-the-day__item">
                        <VacancySummary
                            vacancy={vacancy}
                            vacancyLinkQueryParams={vacancyLinkQueryParams}
                            onClick={() => Analytics.sendHHEventButtonClick('VOD_from_article')}
                            setVacancyRef={(ref) => {
                                if (ref && vacanciesRefs.current) {
                                    vacanciesRefs.current[vacancy.vacancyId] = ref;
                                }
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default translation(VacanciesOfTheDay);
