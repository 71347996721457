import React, { FC } from 'react';

import DropDown from 'bloko/blocks/drop/Down';
import { DropPlacement } from 'bloko/blocks/drop/constants';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import useToggleState from 'src/hooks/useToggleState';
import { Participant } from 'src/models/insiderArticleSpecificData';

import InsiderSimpleParticipantInfo from 'src/pages/Article/components/Sidebar/InsiderParticipants/InsiderSimpleParticipantInfo';

const InsiderSimpleParticipant: FC<{ participant: Participant }> = ({ participant }) => {
    const [show, toggleShow, setShow] = useToggleState(false);
    const breakpoint = useBreakpoint(Breakpoint.XS);
    const isXs = breakpoint === Breakpoint.XS;

    return (
        <div className="interview-participant">
            <DropDown
                show={!isXs && show}
                placement={DropPlacement.TopStart}
                render={() => (
                    <div className="interview-dropdown">
                        <InsiderSimpleParticipantInfo participant={participant} />
                    </div>
                )}
                onClose={() => {
                    setShow(false);
                }}
                flexible
            >
                <div onClick={toggleShow}>
                    <img
                        loading="lazy"
                        src={participant.smallPicture}
                        className="interview-participant__image"
                        alt={participant.name}
                    />
                    <div className="interview-participant__under-image" />
                </div>
            </DropDown>
            <div className="interview-participant__info">
                <InsiderSimpleParticipantInfo participant={participant} />
            </div>
        </div>
    );
};

export default InsiderSimpleParticipant;
