import { FC } from 'react';

import HumanDate, { ShowYear } from 'src/components/HumanDate';

type Props = {
    eventStartDate?: string;
    eventStartDateShort?: string;
    eventEndDate?: string;
};

const ArticleEventDates: FC<Props> = ({ eventStartDateShort, eventStartDate, eventEndDate }) => (
    <>
        {eventStartDateShort}
        {!eventStartDateShort && eventStartDate && <HumanDate date={eventStartDate} showYear={ShowYear.Never} />}
        {eventEndDate && (
            <>
                {' - '}
                <HumanDate date={eventEndDate} showYear={ShowYear.Never} />
            </>
        )}
    </>
);

export default ArticleEventDates;
