import { ReadMoreArticle } from 'src/models/articlePage';
import { ArticleTypes } from 'src/models/articlePage/types';
import { BlogArticle } from 'src/models/blogPage';

export const getArticleImage = (
    articleItem: BlogArticle | ReadMoreArticle,
    imageType = 'readmore'
): string | undefined => {
    if ('preview' in articleItem) {
        return articleItem.preview;
    }
    return articleItem.images?.find(({ type }) => type === imageType)?.url;
};

export const useGetArticleHref = (articleItem: BlogArticle | ReadMoreArticle, articleId?: string): string => {
    const archiveTempHash =
        'archiveHash' in articleItem && articleItem.archiveHash !== ''
            ? `${articleId ? '&' : '?'}archive=${articleItem.archiveHash}`
            : '';
    const fromArticle = articleId ? `?from=article_${articleId}` : '';
    if ('articleType' in articleItem && articleItem.articleType === ArticleTypes.Insider) {
        return `/interview/${articleItem.code}${fromArticle}${archiveTempHash}`;
    }
    return `/article/${articleItem.code}${fromArticle}${archiveTempHash}`;
};
