import React, { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { ArticleTypes } from 'src/models/articlePage/types';

import styles from './article-lead.less';

interface ArticleLeadProps {
    small?: boolean;
}

const ArticleLead: FC<ArticleLeadProps> = ({ small = false }) => {
    const { lead, announce, type } = useSelector(({ articlePage }) => articlePage);

    if (type === ArticleTypes.PressRelease) {
        return <div className={styles.lead}>{announce}</div>;
    }

    if (!lead) {
        return null;
    }

    return <div className={small ? styles.leadSmall : styles.lead} dangerouslySetInnerHTML={{ __html: lead }} />;
};

export default ArticleLead;
