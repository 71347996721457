import { FC, PropsWithChildren } from 'react';

const RowContent: FC<
    {
        isShrinked?: boolean;
    } & PropsWithChildren
> = ({ children, isShrinked }) => {
    const cls = isShrinked ? 'row-content_schrinked' : 'row-content';
    return <div className={cls}>{children}</div>;
};

export default RowContent;
