import React, { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { ImageType, InsiderDesignType } from 'src/models/articlePage';
import { ArticleTypes } from 'src/models/articlePage/types';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import ArticleLead from 'src/pages/Article/components/ContentHeader/ArticleLead';
import InsiderSimpleHeader from 'src/pages/Article/components/ContentHeader/InsiderSimpleHeader';
import LandingContentHeader from 'src/pages/Article/components/ContentHeader/LandingContentHeader';
import LeadWithAuthor from 'src/pages/Article/components/ContentHeader/LeadWithAuthor/LeadWithAuthor';

const ContentHeader: FC = () => {
    const isInsider = useIsInsider();
    const { insiderDesignType, type, authorPhotos } = useSelector(({ articlePage }) => articlePage);
    const authorPhoto = authorPhotos?.find(({ type }) => type === ImageType.Related);

    if (isInsider && insiderDesignType === InsiderDesignType.Simple) {
        return <InsiderSimpleHeader />;
    }

    if (type === ArticleTypes.PressRelease || (isInsider && insiderDesignType === InsiderDesignType.Landing)) {
        return <LandingContentHeader />;
    }

    if (authorPhoto) {
        return <LeadWithAuthor photo={authorPhoto} />;
    }

    return <ArticleLead />;
};

export default ContentHeader;
