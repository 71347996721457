const NUMBER_LIMIT = 1000;

export const formatNumberToTenthsDigit = (number: number): string => {
    if (number < NUMBER_LIMIT) {
        return `${number}`;
    }

    const quotient = number / NUMBER_LIMIT;
    const formattedNumber = Number(quotient.toFixed(1));

    return `${formattedNumber}k`;
};
