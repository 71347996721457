import { HSpacing, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ArticleLead from 'src/pages/Article/components/ContentHeader/ArticleLead';
import LandingTextContainer from 'src/pages/Article/components/LandingTextContainer';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import InsiderParticipant from 'src/pages/Article/components/ContentHeader/LandingContentHeader/InsiderParticipant';

import styles from './landing-content-header.less';

const TrlKeys = {
    companyVacancies: 'article.insiderInterview.vacancies',
    otherInterviews: 'article.insiderInterview.otherInterviews',
};

const LandingContentHeader: TranslatedComponent = ({ trls }) => {
    const info = useSelector((state) => state.articleEmployerInfo);
    const otherInterviews = useSelector((state) => state.insiderArticleSpecificData?.otherInterviews);
    const participants = useSelector((state) => state.insiderArticleSpecificData?.participants);
    const isInsider = useIsInsider();

    return (
        <>
            {!!info && (
                <>
                    <LandingTextContainer>
                        {info.logo && <img loading="lazy" src={info.logo} className={styles.logo} alt={info.name} />}
                        <div className={styles.container}>
                            <H2>{info.name}</H2>
                            {info.siteUrl && <Link to={info.siteUrl}>{info.siteUrl}</Link>}
                            {!!info.industries.length && (
                                <>
                                    <VSpacing default={12} />
                                    {info.industries.map((industry) => (
                                        <p key={industry}>{industry}</p>
                                    ))}
                                </>
                            )}
                        </div>
                    </LandingTextContainer>
                    {isInsider && (
                        <>
                            <LandingTextContainer>
                                <div className={styles.container}>
                                    <VSpacing default={12} />
                                    <div className={styles.linksContainer}>
                                        <BlokoLink Element={Link} to={`/employer/${info.id}`} disableVisited>
                                            {trls[TrlKeys.companyVacancies]}
                                        </BlokoLink>
                                        {otherInterviews && otherInterviews.length > 0 && (
                                            <div>
                                                <BlokoLink href="#interview" disableVisited>
                                                    {trls[TrlKeys.otherInterviews]}
                                                </BlokoLink>
                                                <HSpacing default={6} />
                                                <span className={styles.interviewCount}>{otherInterviews.length}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </LandingTextContainer>
                            <VSpacing default={24} />
                            <div className={styles.participants}>
                                {participants?.map((participant, index) => (
                                    <LandingTextContainer key={participant.id}>
                                        {index > 0 && <VSpacing default={20} />}
                                        <InsiderParticipant participant={participant} />
                                    </LandingTextContainer>
                                ))}
                            </div>
                            <VSpacing default={20} />
                        </>
                    )}
                    <VSpacing default={24} />
                </>
            )}
            <LandingTextContainer>
                <ArticleLead />
            </LandingTextContainer>
        </>
    );
};

export default translation(LandingContentHeader);
