import React from 'react';

import LightPageLayoutContent from 'src/app/layouts/BlokoLayouts/LightPageLayout/LightPageLayoutContent';
import LightPageLayoutFooter from 'src/app/layouts/BlokoLayouts/LightPageLayout/LightPageLayoutFooter';
import LightPageLayoutHeader from 'src/app/layouts/BlokoLayouts/LightPageLayout/LightPageLayoutHeader';
import { LightPageCommonProps } from 'src/app/layouts/BlokoLayouts/LightPageLayout/lightPage.types';

export interface LightPageProps extends LightPageCommonProps {
    children: React.FC<LightPageChildrenProps>;
}

interface LightPageChildrenProps {
    footer: React.ReactElement;
    header: React.ReactElement;
    Content: React.FC<React.PropsWithChildren>;
}

const LightPage: React.FC<LightPageProps> = ({ children, noContentPadding, noBackground, promo, error }) => {
    const Content = React.useMemo(
        () => LightPageLayoutContent({ noContentPadding, noBackground, error }),
        [noContentPadding, noBackground, error]
    );

    return children({
        header: <LightPageLayoutHeader promo={promo} />,
        Content,
        footer: <LightPageLayoutFooter isContrast={!noBackground} />,
    });
};

export default LightPage;
